




import AccountRegistrationComplete from '@/components/organisms/AccountRegistration/AccountRegistrationComplete.vue'
import menuStore from '@/store/Menu'
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: { AccountRegistrationComplete } })
export default class extends Vue {
  mounted(): void {
    menuStore.setInvisible()
  }
}
